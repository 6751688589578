import { createInjectionState } from '@vueuse/core';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  isAfter,
} from 'date-fns';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { fetchPropertyAvailability } from '@/availability/property-availability/property-availability.api';
import { mergeDailyPropertyAvailabilitiesWithPropertyAvailability } from '@/availability/property-availability/property-availability.utilities';
import { useAvailabilityCalendarStatusGenerator } from '@/availability-calendar/availability-calendar.composable';
import type { AvailabilityStatus } from '@/availability-calendar/availability-status/availability-status';
import { AvailabilityStatusType } from '@/availability-calendar/availability-status/availability-status';
import {
  getAvailabilityForArrival,
  getAvailabilityForDeparture,
  getDatesToUnavailabilityMap,
} from '@/availability-calendar/availability-status/availability-status.utilities';
import {
  createDateObject,
  getDatesInRange,
  isBeforeToday,
} from '@/date/date.utilities';
import { getWeekStartIndexOfLocale } from '@/intl/intl.utilities';
import type { Property } from '@/property/property';
import { getPropertyUnitIds } from '@/property/property.utilities';
import type { StayDates } from '@/stay-dates/stay-dates';

export const MONTHS_TO_ADD = 5;

const [useProvideAvailabilityCalendarStore, useMaybeAvailabilityCalendarStore] =
  createInjectionState(
    (injectionState: {
      property: ComputedRef<Property>;
      unitIds?: ComputedRef<number[]> | undefined;
      showAllDatesAsAvailable?: boolean;
    }) => {
      const property = injectionState.property;

      const unitIds =
        injectionState.unitIds ??
        computed(() => getPropertyUnitIds(property.value));

      const showAllDatesAsAvailable =
        injectionState.showAllDatesAsAvailable ?? false;

      const propertyAvailability = ref<PropertyAvailability>();

      const selectedCheckInDate = ref<number>();
      const selectedCheckOutDate = ref<number>();

      const dateBeingHovered = ref<number>();

      const dateToAvailabilityStatusMap = ref(
        new Map<string, AvailabilityStatus>(),
      );

      const selectedDate = ref<number>();
      const availabilityOnSelectedDate = ref<AvailabilityStatus>();
      const monthsWithAvailability = ref(new Set<number>());
      const monthsWithLoadingAvailability = ref(new Set<number>());

      const showCalendarStatusError = ref(false);
      const animateCalendarStatusError = ref(false);

      const {
        generateCalendarStatusHeading,
        generateCalendarStatusSubHeading,
      } = useAvailabilityCalendarStatusGenerator();

      const setDateBeingHovered = (newDateBeingHovered: number) => {
        dateBeingHovered.value = newDateBeingHovered;
      };

      const unsetDateBeingHovered = () => {
        dateBeingHovered.value = undefined;
      };

      const selectedStayDates = computed<StayDates | undefined>(() => {
        if (!selectedCheckInDate.value || !selectedCheckOutDate.value) {
          return;
        }

        return {
          checkInDate: format(selectedCheckInDate.value, 'yyyy-MM-dd'),
          checkOutDate: format(selectedCheckOutDate.value, 'yyyy-MM-dd'),
        };
      });

      const dateToDailyPropertyAvailabilityMap = computed(
        () =>
          new Map(
            propertyAvailability.value?.dailyPropertyAvailabilities.map(
              (dailyPropertyAvailability) => [
                dailyPropertyAvailability.date,
                dailyPropertyAvailability,
              ],
            ),
          ),
      );

      const firstUnavailableDateForDeparture = computed(() => {
        if (isOnlyCheckInDateSelected.value) {
          return getFirstUnavailableDateForDeparture(
            selectedCheckInDate.value!,
            unitIds.value,
          );
        }
        return undefined;
      });

      const isNoDateSelected = computed(
        () =>
          selectedCheckInDate.value === undefined &&
          selectedCheckOutDate.value === undefined,
      );

      const isCheckInAndCheckOutDateSelected = computed(
        () =>
          selectedCheckInDate.value !== undefined &&
          selectedCheckOutDate.value !== undefined,
      );

      const isOnlyCheckInDateSelected = computed(
        () =>
          selectedCheckInDate.value !== undefined &&
          selectedCheckOutDate.value === undefined,
      );

      const calendarStatusHeading = computed(() =>
        generateCalendarStatusHeading(
          selectedCheckInDate.value,
          selectedCheckOutDate.value,
        ),
      );

      const calendarStatusSubHeading = computed(() =>
        generateCalendarStatusSubHeading(
          selectedDate.value,
          selectedCheckInDate.value,
          selectedCheckOutDate.value,
          availabilityOnSelectedDate.value,
        ),
      );

      const weekStartIndex = getWeekStartIndexOfLocale(navigator.language);

      const getAvailabilityStatusOnDate = (
        date: number,
      ): AvailabilityStatus => {
        const dateToCheck = format(date, 'yyyy-MM-dd');

        if (isBeforeToday(dateToCheck, property.value.timezone)) {
          return {
            isAvailable: false,
            reason: { type: AvailabilityStatusType.NotAvailable },
          };
        }

        if (
          isNoDateSelected.value ||
          isCheckInAndCheckOutDateSelected.value ||
          !isAfter(date, selectedCheckInDate.value!)
        ) {
          return getAvailabilityForArrivalDate(dateToCheck, unitIds.value);
        }

        return getAvailabilityForDepartureDate(dateToCheck, unitIds.value);
      };

      const getAvailabilityForArrivalDate = (
        dateToCheck: string,
        unitIds: number[],
      ): AvailabilityStatus => {
        let availabilityStatusOnArrivalDate =
          dateToAvailabilityStatusMap.value.get(dateToCheck);
        if (availabilityStatusOnArrivalDate !== undefined) {
          return availabilityStatusOnArrivalDate;
        }

        availabilityStatusOnArrivalDate = getAvailabilityForArrival(
          dateToDailyPropertyAvailabilityMap.value.get(dateToCheck)!,
          property.value,
          unitIds,
        );

        dateToAvailabilityStatusMap.value.set(
          dateToCheck,
          availabilityStatusOnArrivalDate,
        );

        return availabilityStatusOnArrivalDate;
      };

      const getAvailabilityForDepartureDate = (
        dateToCheck: string,
        unitIds: number[],
      ): AvailabilityStatus => {
        if (
          differenceInDays(
            createDateObject(dateToCheck),
            selectedCheckInDate.value!,
          ) > property.value.maxStayLength ||
          isAfter(
            createDateObject(dateToCheck),
            createDateObject(firstUnavailableDateForDeparture.value!),
          )
        ) {
          return {
            isAvailable: false,
            reason: { type: AvailabilityStatusType.NotAvailable },
          };
        }

        const datesOfStay = getDatesInRange(
          format(selectedCheckInDate.value!, 'yyyy-MM-dd'),
          dateToCheck,
        );

        const dailyPropertyAvailabilities = datesOfStay.map(
          (dateOfStay) =>
            dateToDailyPropertyAvailabilityMap.value.get(dateOfStay)!,
        );

        return getAvailabilityForDeparture(
          dailyPropertyAvailabilities,
          unitIds,
        );
      };

      const getFirstUnavailableDateForDeparture = (
        selectedCheckInDate: number,
        unitIds: number[],
      ) => {
        const dailyPropertyAvailabilities = eachDayOfInterval({
          start: selectedCheckInDate,
          end: addDays(selectedCheckInDate, property.value.maxStayLength - 1),
        }).map((dateOfStay) =>
          dateToDailyPropertyAvailabilityMap.value.get(
            format(dateOfStay, 'yyyy-MM-dd'),
          ),
        );

        const datesToUnavailabilityMap = getDatesToUnavailabilityMap(
          dailyPropertyAvailabilities,
          unitIds,
        );

        let firstUnavailableDate;

        for (const [
          date,
          isUnavailable,
        ] of datesToUnavailabilityMap.entries()) {
          if (isUnavailable) {
            firstUnavailableDate = date;
            break;
          }
        }
        return firstUnavailableDate;
      };

      const load = async (fromDate: string, toDate: string) => {
        const givenMonths = eachMonthOfInterval({
          start: createDateObject(fromDate),
          end: createDateObject(toDate),
        }).map((month) => month.getTime());

        const monthsWithMissingAvailability = givenMonths.filter(
          (month) =>
            !monthsWithAvailability.value.has(month) &&
            !monthsWithLoadingAvailability.value.has(month),
        );

        if (monthsWithMissingAvailability.length !== 0) {
          monthsWithMissingAvailability.forEach((month) =>
            monthsWithLoadingAvailability.value.add(month),
          );

          const firstDateWithMissingAvailability = format(
            monthsWithMissingAvailability.at(0)!,
            'yyyy-MM-dd',
          );

          const lastDateWithMissingAvailability = format(
            endOfMonth(new Date(monthsWithMissingAvailability.at(-1)!)),
            'yyyy-MM-dd',
          );

          const newPropertyAvailability = showAllDatesAsAvailable
            ? makePropertyAvailabilityToShowAllDatesAsAvailable(
                firstDateWithMissingAvailability,
                lastDateWithMissingAvailability,
              )
            : await fetchPropertyAvailability(property.value.id, {
                fromDate: firstDateWithMissingAvailability,
                toDate: lastDateWithMissingAvailability,
              });

          if (propertyAvailability.value) {
            propertyAvailability.value =
              mergeDailyPropertyAvailabilitiesWithPropertyAvailability(
                propertyAvailability.value,
                newPropertyAvailability.dailyPropertyAvailabilities,
              );
          } else {
            propertyAvailability.value = newPropertyAvailability;
          }

          monthsWithMissingAvailability.forEach((month) => {
            monthsWithLoadingAvailability.value.delete(month);
            monthsWithAvailability.value.add(month);
          });
        }
      };

      const reset = () => {
        monthsWithAvailability.value.clear();
        monthsWithLoadingAvailability.value.clear();
        dateToAvailabilityStatusMap.value.clear();
      };

      const clearSelectedDates = () => {
        selectedDate.value = undefined;
        selectedCheckInDate.value = undefined;
        selectedCheckOutDate.value = undefined;
        availabilityOnSelectedDate.value = undefined;
      };

      /**
       * Creates 'fake' availability for the current property that will allow
       * all dates in the given range to be shown as available.
       */
      const makePropertyAvailabilityToShowAllDatesAsAvailable = (
        fromDate: string,
        toDate: string,
      ): PropertyAvailability => ({
        propertyId: property.value.id,
        dailyPropertyAvailabilities: getDatesInRange(fromDate, toDate).map(
          (date) => ({
            date,
            isClosedToArrival: false,
            isClosedToDeparture: false,
            isClosedToDiscountWaysToSell: false,
            unitAvailabilities: property.value.units.map((unit) => ({
              unitId: unit.id,
              isClosedOut: false,
              minimumStay: 1,
              allocation: 1,
              rate: 1,
              pseudoUnitAvailabilities: [],
            })),
            mealAvailabilities: [],
            offerAvailabilities: [],
            privateRateOverrideAvailabilities: [],
          }),
        ),
      });

      return {
        dateToAvailabilityStatusMap,
        dateToDailyPropertyAvailabilityMap,
        availabilityOnSelectedDate,
        selectedDate,
        dateBeingHovered,
        selectedCheckInDate,
        selectedCheckOutDate,
        selectedStayDates,
        showCalendarStatusError,
        animateCalendarStatusError,
        isNoDateSelected,
        isCheckInAndCheckOutDateSelected,
        isOnlyCheckInDateSelected,
        calendarStatusHeading,
        calendarStatusSubHeading,
        monthsWithLoadingAvailability,
        weekStartIndex,
        getAvailabilityStatusOnDate,
        setDateBeingHovered,
        unsetDateBeingHovered,
        reset,
        clearSelectedDates,
        load,
      };
    },
  );

const useAvailabilityCalendarStore = () => {
  const store = useMaybeAvailabilityCalendarStore();

  if (!store) {
    throw new Error(
      'Please call `useProvideAvailabilityCalendarStore` on the appropriate parent component',
    );
  }

  return store;
};

export { useAvailabilityCalendarStore, useProvideAvailabilityCalendarStore };
