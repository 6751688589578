import type {
  DailyPropertyAvailability,
  DatedPropertyAvailabilityDTO,
} from '@/availability/daily-property-availability/daily-property-availability';
import { mapMealAvailabilityFromDTO } from '@/availability/meal-availability/meal-availability.mapper';
import { mapOfferAvailabilityFromDTO } from '@/availability/offer-availability/offer-availability.mapper';
import { mapPrivateRateOverrideAvailabilityFromDTO } from '@/availability/private-rate-override-availability/private-rate-override-availability.mapper';
import { mapUnitAvailabilityFromDTO } from '@/availability/unit-availability/unit-availability.mapper';

export const mapDailyPropertyAvailabilityFromDTO = (
  datedPropertyAvailabilityDTO: DatedPropertyAvailabilityDTO,
): DailyPropertyAvailability => ({
  date: datedPropertyAvailabilityDTO.date,
  isClosedToArrival: datedPropertyAvailabilityDTO.isClosedToArrival,
  isClosedToDeparture: datedPropertyAvailabilityDTO.isClosedToDeparture,
  isClosedToDiscountWaysToSell:
    datedPropertyAvailabilityDTO.isClosedToDiscountWaysToSell,
  unitAvailabilities: datedPropertyAvailabilityDTO.unitAvailabilities.map(
    mapUnitAvailabilityFromDTO,
  ),
  mealAvailabilities: datedPropertyAvailabilityDTO.mealAvailabilities.map(
    mapMealAvailabilityFromDTO,
  ),
  offerAvailabilities: datedPropertyAvailabilityDTO.offerAvailabilities.map(
    mapOfferAvailabilityFromDTO,
  ),
  privateRateOverrideAvailabilities:
    datedPropertyAvailabilityDTO.privateRateOverrideAvailabilities.map(
      mapPrivateRateOverrideAvailabilityFromDTO,
    ),
});
