import type {
  PrivateRateOverrideAvailability,
  PrivateRateOverrideAvailabilityDTO,
} from '@/availability/private-rate-override-availability/private-rate-override-availability';

export const mapPrivateRateOverrideAvailabilityFromDTO = (
  privateRateOverrideAvailabilityDTO: PrivateRateOverrideAvailabilityDTO,
): PrivateRateOverrideAvailability => ({
  privateRateOverrideId:
    privateRateOverrideAvailabilityDTO.privateRateOverrideId,
  isClosedOut: privateRateOverrideAvailabilityDTO.isClosedOut,
});
