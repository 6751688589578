import type { DailyPropertyAvailability } from '@/availability/daily-property-availability/daily-property-availability';
import type { MealAvailability } from '@/availability/meal-availability/meal-availability';
import type { OfferAvailability } from '@/availability/offer-availability/offer-availability';
import type { PrivateRateOverrideAvailability } from '@/availability/private-rate-override-availability/private-rate-override-availability';
import type { UnitAvailability } from '@/availability/unit-availability/unit-availability';
import type { MealType } from '@/property/meal/meal';

export const findDailyPropertyAvailabilityUnitAvailabilityByUnitId = (
  { unitAvailabilities }: DailyPropertyAvailability,
  unitId: number,
): UnitAvailability | undefined =>
  unitAvailabilities.find(
    (unitAvailability) => unitAvailability.unitId === unitId,
  );

export const findDailyPropertyAvailabilityMealAvailabilityByMealType = (
  { mealAvailabilities }: DailyPropertyAvailability,
  mealType: MealType,
): MealAvailability | undefined =>
  mealAvailabilities.find(
    (mealAvailability) => mealAvailability.mealType === mealType,
  );

export const findDailyPropertyAvailabilityOfferAvailabilityByOfferId = (
  { offerAvailabilities }: DailyPropertyAvailability,
  offerId: string,
): OfferAvailability | undefined =>
  offerAvailabilities.find(
    (offerAvailability) => offerAvailability.offerId === offerId,
  );

export const findDailyPropertyAvailabilityPrivateRateOverrideAvailabilityByPrivateRateOverrideId =
  (
    { privateRateOverrideAvailabilities }: DailyPropertyAvailability,
    privateRateOverrideId: string,
  ): PrivateRateOverrideAvailability | undefined =>
    privateRateOverrideAvailabilities.find(
      (privateRateOverrideAvailability) =>
        privateRateOverrideAvailability.privateRateOverrideId ===
        privateRateOverrideId,
    );

export const findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail = (
  dailyPropertyAvailability: DailyPropertyAvailability,
  unitId: number,
): UnitAvailability => {
  const unitAvailability =
    findDailyPropertyAvailabilityUnitAvailabilityByUnitId(
      dailyPropertyAvailability,
      unitId,
    );

  if (!unitAvailability) {
    throw new Error(`Could not find availability for unit ${unitId}`);
  }

  return unitAvailability;
};
