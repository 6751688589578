import type { DailyPropertyAvailability } from '@/availability/daily-property-availability/daily-property-availability';
import {
  findDailyPropertyAvailabilityMealAvailabilityByMealType,
  findDailyPropertyAvailabilityOfferAvailabilityByOfferId,
  findDailyPropertyAvailabilityPrivateRateOverrideAvailabilityByPrivateRateOverrideId,
  findDailyPropertyAvailabilityUnitAvailabilityByUnitId,
} from '@/availability/daily-property-availability/daily-property-availability.utilities';
import type { MealAvailability } from '@/availability/meal-availability/meal-availability';
import type { OfferAvailability } from '@/availability/offer-availability/offer-availability';
import type { PrivateRateOverrideAvailability } from '@/availability/private-rate-override-availability/private-rate-override-availability';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import type { UnitAvailability } from '@/availability/unit-availability/unit-availability';
import type { MealType } from '@/property/meal/meal';

export const getPropertyAvailabilityStayDates = ({
  dailyPropertyAvailabilities,
}: PropertyAvailability): { checkInDate: string; checkOutDate: string } => {
  let checkInDate: string | undefined;
  let checkOutDate: string | undefined;

  for (const { date } of dailyPropertyAvailabilities) {
    if (!checkInDate || date < checkInDate) {
      checkInDate = date;
    }

    if (!checkOutDate || date > checkOutDate) {
      checkOutDate = date;
    }
  }

  if (!checkInDate || !checkOutDate) {
    throw new Error('Could not find stay dates for property availability');
  }

  return { checkInDate, checkOutDate };
};

export const findPropertyAvailabilityByPropertyId = (
  propertyAvailabilities: PropertyAvailability[],
  propertyId: number,
): PropertyAvailability | undefined =>
  propertyAvailabilities.find(
    (propertyAvailability) => propertyAvailability.propertyId === propertyId,
  );

export const findPropertyAvailabilityDailyPropertyAvailabilityByDate = (
  { dailyPropertyAvailabilities }: PropertyAvailability,
  date: string,
): DailyPropertyAvailability | undefined =>
  dailyPropertyAvailabilities.find(
    (dailyPropertyAvailability) => dailyPropertyAvailability.date === date,
  );

export const findPropertyAvailabilityUnitAvailabilityByDate = (
  propertyAvailability: PropertyAvailability,
  unitId: number,
  date: string,
): UnitAvailability | undefined => {
  const dailyPropertyAvailability =
    findPropertyAvailabilityDailyPropertyAvailabilityByDate(
      propertyAvailability,
      date,
    );

  if (dailyPropertyAvailability) {
    return findDailyPropertyAvailabilityUnitAvailabilityByUnitId(
      dailyPropertyAvailability,
      unitId,
    );
  }

  return undefined;
};

export const findPropertyAvailabilityMealAvailabilityByDate = (
  propertyAvailability: PropertyAvailability,
  mealType: MealType,
  date: string,
): MealAvailability | undefined => {
  const dailyPropertyAvailability =
    findPropertyAvailabilityDailyPropertyAvailabilityByDate(
      propertyAvailability,
      date,
    );

  if (dailyPropertyAvailability) {
    return findDailyPropertyAvailabilityMealAvailabilityByMealType(
      dailyPropertyAvailability,
      mealType,
    );
  }

  return undefined;
};

export const findPropertyAvailabilityOfferAvailabilityByDate = (
  propertyAvailability: PropertyAvailability,
  offerId: string,
  date: string,
): OfferAvailability | undefined => {
  const dailyPropertyAvailability =
    findPropertyAvailabilityDailyPropertyAvailabilityByDate(
      propertyAvailability,
      date,
    );

  if (dailyPropertyAvailability) {
    return findDailyPropertyAvailabilityOfferAvailabilityByOfferId(
      dailyPropertyAvailability,
      offerId,
    );
  }

  return undefined;
};

export const findPropertyAvailabilityPrivateRateOverrideAvailabilityByDate = (
  propertyAvailability: PropertyAvailability,
  privateRateOverrideId: string,
  date: string,
): PrivateRateOverrideAvailability | undefined => {
  const dailyPropertyAvailability =
    findPropertyAvailabilityDailyPropertyAvailabilityByDate(
      propertyAvailability,
      date,
    );

  if (dailyPropertyAvailability) {
    return findDailyPropertyAvailabilityPrivateRateOverrideAvailabilityByPrivateRateOverrideId(
      dailyPropertyAvailability,
      privateRateOverrideId,
    );
  }

  return undefined;
};

export const findPropertyAvailabilityByPropertyIdOrFail = (
  propertyAvailabilities: PropertyAvailability[],
  propertyId: number,
): PropertyAvailability => {
  const propertyAvailability = findPropertyAvailabilityByPropertyId(
    propertyAvailabilities,
    propertyId,
  );

  if (!propertyAvailability) {
    throw new Error(`Could not find availability for property ${propertyId}`);
  }

  return propertyAvailability;
};

export const findPropertyAvailabilityDailyPropertyAvailabilityByDateOrFail = (
  propertyAvailability: PropertyAvailability,
  date: string,
): DailyPropertyAvailability => {
  const dailyPropertyAvailability =
    findPropertyAvailabilityDailyPropertyAvailabilityByDate(
      propertyAvailability,
      date,
    );

  if (!dailyPropertyAvailability) {
    throw new Error(`Could not find availability on ${date}`);
  }

  return dailyPropertyAvailability;
};

export const findPropertyAvailabilityUnitAvailabilityByDateOrFail = (
  propertyAvailability: PropertyAvailability,
  unitId: number,
  date: string,
): UnitAvailability => {
  const unitAvailability = findPropertyAvailabilityUnitAvailabilityByDate(
    propertyAvailability,
    unitId,
    date,
  );

  if (!unitAvailability) {
    throw new Error(
      `Could not find availability for unit ${unitId} on ${date}`,
    );
  }

  return unitAvailability;
};

export const findPropertyAvailabilityMealAvailabilityByDateOrFail = (
  propertyAvailability: PropertyAvailability,
  mealType: MealType,
  date: string,
): MealAvailability => {
  const mealAvailability = findPropertyAvailabilityMealAvailabilityByDate(
    propertyAvailability,
    mealType,
    date,
  );

  if (!mealAvailability) {
    throw new Error(
      `Could not find availability for meal '${mealType}' on ${date}`,
    );
  }

  return mealAvailability;
};

export const mergeDailyPropertyAvailabilitiesWithPropertyAvailability = (
  propertyAvailability: PropertyAvailability,
  dailyPropertyAvailabilities: DailyPropertyAvailability[],
): PropertyAvailability => ({
  ...propertyAvailability,
  dailyPropertyAvailabilities: [
    ...propertyAvailability.dailyPropertyAvailabilities,
    ...dailyPropertyAvailabilities,
  ],
});
